import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import PaysService from "../../../services/PaysService";
import DossiersService from "../../../services/DossiersService";
import StatutsdossiersService from "../../../services/StatutsdossiersService";
import CategoriesService from "../../../services/CategoriesService";
import ObjetsService from "../../../services/ObjetsService";
import { Dialog } from "primereact/dialog";
import TypeschambresService from "../../../services/TypeschambresService";
import ChambresService from "../../../services/ChambresService";
import {
  convertDateToMinTexte,
  convertDateToTexte,
  downloadSingleFile,
  getLatestDate,
  setDataTable,
} from "../../../utils/helpers";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import { app_url } from "../../../services/http";
import StatutsService from "../../../services/StatutsService";
import { Tag } from "primereact/tag";
import { saveAs } from "file-saver";

export default function SaisinesIndex() {
  //##########################/// METHODES ##########################///
  const [dossiers, setDossiers] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const [typeschambres, setTypeschambres] = useState([]);
  const [typechambre_id, setTypechambre_id] = useState("");
  const [utilisateurs, setUtilisateurs] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [paysTrouve, setPaysTrouve] = useState([]);
  const [statutTrouve, setStatutTrouve] = useState([]);
  const [objets, setObjets] = useState([]);
  const [statutsdossiers, setStatutsdossiers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dossiersfiles, setDossiersfiles] = useState([]);

  const [modalLabel, setModalLabel] = useState("");
  const [modalObjet, setModalObjet] = useState([]);
  const [dossierspartiesdocuments, setDossierspartiesdocuments] = useState([]);

  const [showModal, setShowModal] = useState(false); // État pour contrôler la visibilité du modal

  const handleShowModal = () => setShowModal(true); // Fonction pour afficher le modal
  const handleCloseModal = () => setShowModal(false); // Fonction pour fermer le modal
  const profils = useSelector((state) => state.auth.profil);

  //get liste statuts
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((res) => {
        setStatutsdossiers(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statutsdossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };

  //get liste pays
  const fetchPays = async () => {
    await PaysService.index(token)
      .then((res) => {
        setPays(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste chambres
  const fetchChambres = async () => {
    await ChambresService.index(token)
      .then((res) => {
        setTypeschambres(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNomPays = (paysId) => {
    const paysTrouve = pays.find((pays) => pays.id === paysId);
    return paysTrouve ? paysTrouve.nom_pays : "N/A";
  };

  //get liste categories dossiers
  const fetchObjets = async () => {
    await ObjetsService.index(token)
      .then((res) => {
        setObjets(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getObjets = (objet_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(objet_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Objet = objets.find((item) => {
      return item.id == idToFind;
    });

    return Objet ? Objet.libelle : "N/A";
  };

  //get liste categories dossiers
  const fetchCategories = async () => {
    await CategoriesService.index(token)
      .then((res) => {
        setCategories(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLibelleCat = (categorie_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(categorie_id);

    // Recherchez le libellé dans la liste des catégories
    const categorie = categories.find((item) => {
      return item.id == idToFind;
    });

    return categorie ? categorie.libelle : "";
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossiers(responseData.data);
        //console.log("responseData.datadossier", responseData.data)
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  //Afficher le modal de details
  const handleShowDetails = (option) => {
    setModalLabel(option.reference_dossier);
    const filteredData = dossierspartiesdocuments.filter(
      (opt) => parseInt(opt.dossier_id) === option.id
    );
    setModalObjet(filteredData);
    //console.log(filteredData)
    handleShowModal();
  };

  //get liste dossiers
  const fetchDossiersfiles = async () => {
    try {
      const response = await DossierspartiesdocumentsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossierspartiesdocuments(responseData.data);
        //console.log("responseData.datafiles", responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        DossiersService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchDossiers();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchStatuts = async () => {
    await StatutsService.index(token)
      .then((res) => {
        setStatuts(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fonction pour verifier le statut du paiement pour un dossier
  const getStatutPaiement = (statut_id) => {
    const dataFiltrer = statuts.filter(
      (option) => option.id == parseInt(statut_id)
    );
    const libelle = dataFiltrer.length > 0 ? dataFiltrer[0].libelle : null;

    return libelle;
  };

  const goToDossier = (dossier_ref) => {
    navigate(`${myroutes.dossiers_show}/${dossier_ref}`);
  };

  const handleDownload = (path) => {
    saveAs(`${app_url}storage/${path}`, path);
  };

  
  useEffect(() => {
    fetchDossiers();
    fetchStatutsdossiers();
    fetchObjets();
    fetchPays();
    fetchCategories();
    fetchChambres();
    fetchDossiersfiles();
    fetchStatuts();
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Sommaire des saisines</h1>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Référence Affaire</th>
                        <th className="">Jugement attaqué</th>
                        <th className="">Parties</th>
                        <th className="">Date Audience</th>
                        <th className="">Statut paiement</th>
                        <th className="">Date d'ajout</th>
                        <th className="">Activité</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {dossiers.length > 0 &&
                        dossiers.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              {profils.includes("ADMIN") ||
                              profils.includes("SUPERADMIN") ? (
                                <a href="#">
                                  <b
                                    onClick={() => goToDossier(item.ref)}
                                    title="Afficher le dossier"
                                  >
                                    {item.reference_dossier}
                                  </b>
                                </a>
                              ) : (
                                <a href="#">
                                  <b>{item.reference_dossier}</b>
                                </a>
                              )}
                            </td>
                            <td className="cell">
                              {item.jugement_attaque} du{" "}
                              {formatDate(item.date_jugement)} au {item.get_tribunal?.libelle}
                            </td>
                            <td className="cell">
                              {item.get_partie.length > 0 &&
                                Object.values(
                                  item.get_partie.reduce((acc, item) => {
                                    const type_partie_id = item.type_partie_id;
                                    if (!acc[type_partie_id]) {
                                      acc[type_partie_id] = [];
                                    }
                                    acc[type_partie_id].push(item);
                                    return acc;
                                  }, {})
                                ).map((group, idx) => (
                                  <div key={idx} style={{ fontSize: "12px" }}>
                                    <span className="text-center">
                                      {group[0].type_partie_id !== 1 && "C/"}
                                    </span>
                                    <ul>
                                      {group.map((item, index) =>
                                        item.is_avocat ? (
                                          <span key={index}>
                                            <b>(</b>
                                            {item.get_assujetie.raison_sociale}
                                            <b>)</b>
                                          </span>
                                        ) : (
                                          <li key={index}>
                                            {item.is_avocat ? (
                                              <>
                                                <b>(</b>
                                                {
                                                  item.get_assujetie
                                                    .raison_sociale
                                                }
                                                <b>)</b>
                                              </>
                                            ) : (
                                              item.get_assujetie.raison_sociale
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ))}
                            </td>

                            <td className="cell" style={{ textAlign: "left" }}>
                              {item &&
                                item.get_date_audience &&
                                item.get_date_audience.length > 0 &&
                                (() => {
                                  const latestDate = getLatestDate(
                                    item.get_date_audience
                                  );
                                  return latestDate;
                                })()}

                                {/*
                                {item &&
                                item.get_date_audience &&
                                item.get_date_audience.length > 0 &&
                                item.get_date_audience.map((option, index) => (
                                  <li key={index}>
                                    {option.get_calendrier
                                      ? convertDateToMinTexte(
                                          option.get_calendrier.jour
                                        )
                                      : "N/A"}
                                  </li>
                                ))}
                                */}
                            </td>
                            <td>
                                  {item.get_paiement && item.get_paiement.length > 0 ? (
                                    [...new Set(
                                      item.get_paiement
                                        .filter((opt) => opt.demande_arret_id == null)
                                        .map((opt) => getStatutPaiement(opt.statut_id))
                                    )].map((statut, i) => (
                                      <span
                                        key={i}
                                        className={`badge ${
                                          statut === "PAYE" ? "text-bg-success" : "text-bg-danger"
                                        }`}
                                      >
                                        {statut}
                                      </span>
                                    ))
                                  ) : (
                                    <span className="badge text-bg-danger">AUCUN PAIEMENT</span>
                                  )}
                            </td>

                            
                            <td className="cell">
                              <span>
                                {dayjs(item.created_at).format(
                                  "DD-MM-YYYY à H:m:s"
                                )}
                                {profils.includes("SUPERADMIN") && <>
                                <br /><b>{item.get_created_by &&
                                  item.get_created_by?.nom +
                                    " " +
                                    item.get_created_by?.prenoms}</b></>}
                              </span>
                            </td>

                            <td className="cell">
                              <div className="d-flex">
                                <Button
                                  variant="secondary"
                                  className="icon-font me-2 mb-2"
                                  onClick={(e) => handleShowDetails(item)}
                                  title="Détails"
                                >
                                  <i className="bi bi-file-earmark-font-fill"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
            {/* Modal */}
            <Dialog
              header={"Détails sur l'affaire " + modalLabel}
              visible={showModal}
              maximizable
              onHide={handleCloseModal}
              style={{
                width: "700px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
            >
              <div className="p-fluid">
                <div className="row">
                  <hr />
                  <p className="text-center fw-bold">
                    Documents relatifs aux dossiers{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                {modalObjet.map((item, index) => (
                  <>
                    <p>
                      <b>Auteur : </b> {item.get_createdby.nom} <br />
                      <b>Type document : </b> {item.get_type_document.libelle}
                      <br />
                      <b>Date d'envoi : </b>{" "}
                      {dayjs(item.created_at).format("DD-MM-YYYY à H:m:s")}
                    </p>
                    <a
                      href="#"
                      onClick={() => downloadSingleFile(item.path, token)}
                      className="text-primary fw-bold"
                      key={index}
                    >
                      Télécharger le fichier
                    </a>
                    <hr />
                  </>
                ))}
              </div>

              <div className="p-dialog-footer mt-3">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Fermer
                </Button>
              </div>
            </Dialog>
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
