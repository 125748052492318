import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Checkbox } from "primereact/checkbox";

const EditFormTProcedures = ({
  onSubmit,
  routeBack,
  editData,
  withoutCodeInput,
  displayCheckBox,
}) => {
  const [validationError, setValidationError] = useState({});
  //navigate pour faire les redirections
  const navigate = useNavigate();

  const [formData, setFormData] = useState(editData);
  const [showCheckBox, setShowCheckBox] = useState(displayCheckBox);

  useEffect(() => {
    setFormData(editData);
    //console.log(formData.is_enrolement)
  }, [editData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeDescription = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleChangeIsEnrolement = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      is_enrolement: e.checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <>
      <div className="row g-3 mb-4 align-items-center justify-content-between">
        <div className="col-auto">
          <h1 className="app-page-title mb-0">Modification </h1>
        </div>
      </div>
      {/*//row*/}

      <div className="app-card app-card-settings shadow-lg mb-5">
        <div className="app-card-body py-3">
          <Row>
            {Object.keys(validationError).length > 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-danger">
                    <ul className="mb-0">{<li>{validationError}</li>}</ul>
                  </div>
                </div>
              </div>
            )}
          </Row>
          <Form
            className="mx-3 settings-form"
            style={{ textAlign: "left" }}
            onSubmit={handleSubmit}
          >
            <Row>
              {!withoutCodeInput && (
                <>
                  <Col md={6} sm={12} className="form-group">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Code <i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        className="border  border rounded-2"
                        onChange={handleChange}
                        value={formData.code}
                        placeholder=""
                        name="code"
                        aria-label="code"
                        maxLength={100}
                        minLength={2}
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col
                md={withoutCodeInput ? 12 : 6}
                sm={12}
                className="form-group"
              >
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Libelle <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    className="border  border rounded-2"
                    onChange={handleChange}
                    value={formData.libelle}
                    placeholder=""
                    name="libelle"
                    aria-label="libelle"
                    maxLength={100}
                    minLength={2}
                    type="text"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Description <i className="text-danger"></i>
                  </Form.Label>
                  <ReactQuill
                    value={formData.description || ""}
                    theme="snow"
                    id=""
                    cols="30"
                    onChange={handleChangeDescription}
                    placeholder=""
                  />
                  {/**
                   * <textarea value={formData.description || ''} name="description" id="" cols="30" onChange={handleChange} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
                   */}
                </Form.Group>
              </Col>
            </Row>

            {showCheckBox == true && (
              <>
                <div className="py-3 flex flex-wrap justify-content-center gap-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="is_enrolement"
                      name="is_enrolement"
                      value="1"
                      onChange={handleChangeIsEnrolement}
                      checked={formData.is_enrolement ? true : ""}
                    />
                    <label htmlFor="is_enrolement" className="ml-2">
                      &nbsp;&nbsp;Cocher cette case pour afficher ce type de
                      document lors de l'enrôlement du dossier.
                    </label>
                  </div>
                </div>
              </>
            )}

            <Button type="submit" variant="warning me-3">
              Enregistrer
            </Button>
            <Button variant="secondary" onClick={() => navigate(routeBack)}>
              Annuler
            </Button>
          </Form>
        </div>
        {/*//app-card-body*/}
      </div>
    </>
  );
};

export default EditFormTProcedures;
